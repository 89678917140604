@import '../../ui/media-query/media-query.scss';

body{
    font-family: Open Sans,sans-serif;
    color: #360A3D;
}

.home-page-wrapper{
    display: flex;
    justify-content: center;
}

.background-img{
    width: 98vw;
    position: fixed;
    bottom: 40px;
    z-index: -1;
}

.home-page-layout{
    width: 74%;
    max-width: 1250px;

    header{
        padding: 30px 0;
        img{
            width: 140px;
        }
    }

    .loading-wrapper{
        margin: 0 auto;
        width: 75%;
        height: 550px;
        box-sizing: border-box;
        padding: 0px 50px;
        background: #FFF;
        box-shadow: 0 2px 4px 1px #A9A9A9;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 80px;
        }
    }
    section{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .success-container{
            margin: 0 auto;
            width: 50%;
            box-sizing: border-box;
            padding: 30px 50px 20px;
            background: #FFF;
            box-shadow: 0 2px 4px 1px #A9A9A9;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            .thankyou-text{
                margin: 20px 0;
                font-weight: bolder;
            }
            .visit-text{
                text-align: center;
            }
            img{
                height: 140px;
                margin : 0 30px 10px;
            }
            .charity-link{
                margin: 20px 0 80px 0;
                a{
                    color: #48c3ff;
                    font-weight: bolder;
                }
            }
            .privacy-policy-container{
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                margin: 80px -30px 0;
                a{
                  color: #48c3ff;
                }
              }
        }

        .response-container{
            margin: 0 auto;
            width: 75%;
            height: 550px;
            box-sizing: border-box;
            padding: 0px 50px;
            background: #FFF;
            box-shadow: 0 2px 4px 1px #A9A9A9;
            border-radius: 12px;
            display: flex;
            align-items: center;
            font-size: 18px;
            img{
                height: 400px;
            }
        }
    }
}

footer{
    width: 100%;
    padding: 10px 0px 6px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    background: #2D789F;
    color:#FFF;
    font-size: 13px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .social-links{
        img{
            height: 34px;
            margin: 0 10px;
        }
    }
}

@include tablet-landscape {
    .home-page-layout{
        width: 100%;
        transform: scale(0.85);
        header {
            padding: 0 0 10px;
        }
    }
    .height-override{
        height: 100vh;
    }

    footer{
        .social-links{
            img{
                height: 30px;
            }
        }
    }

}

@include tablet{
    .home-page-wrapper{
        flex-direction: column;
        justify-content:normal;
    }

    .background-img{
        min-width: 100vw;
        width: 100vw;
        position: unset;
        order:2;
        margin-bottom: -5px;
    }
    
    .home-page-layout{
        width: 100%;
        padding: 15px 100px;
        box-sizing: border-box;
        transform: scale(1);
        order: 1;
        flex:1;
        header{
            padding: 15px 0;
            img{
                width: 130px;
            }
        }
        .loading-wrapper{
            width: 100%;
        }
        section{
            flex-direction: column;
            border: none;
            .success-container{
                width: 100%;
                font-size: 16px;
                padding: 30px;
                .thankyou-text{
                    margin: 10px;
                    font-size: 17px;
                }
                img{
                    height: 140px;
                    margin: 0 30px 10px;
                }
                .charity-link{
                    margin: 20px 0 80px 0;
                }
                .privacy-policy-container{
                    font-size: 14px;
                    margin: 40px 0 0;
                }
            } 

            .response-container{
                width: 100%;
                flex-direction: column;
                font-size: 17px;
                height: auto;
                padding: 25px;
                img{
                    height: 300px;
                    margin: 30px 0;
                }
            }
        }
    }

    footer{
        position: static;
        font-size: 12px;
        align-items: flex-start;
        order: 3;
        padding: 5px 0 0;
        .copy-right{
            margin: 10px;
        }
        .social-links{
            img{
                height: 20px;
                margin: 10px;
            }
        }
    }
}
   
@include mobile {
    .background-img{
        margin-bottom: -2px;
    }

    .home-page-layout{
        padding: 0 10px;
        header{
            img{
                width: 100px;
            }
        }
        .loading-wrapper{
            height: 410px;
            img{
                height: 50px;
            }
        }
        section{
            .success-container{
                font-size: 14px;
                padding: 20px;
                .thankyou-text{
                    font-size: 15px;
                }
                img{
                    height: 120px;
                    margin: 0 0px 15px;
                }
                .charity-link{
                    margin: 20px;
                }
                .privacy-policy-container{
                    font-size: 12px;
                    margin: 20px 0 0;
                }
            } 

            .response-container{
                font-size: 15px;
                padding: 0px 15px;
                img{
                    height: 220px;
                }
            }
        }
    }
    
    footer{
        font-size: 13px;
        flex-direction: column;
        padding: 5px 0;
        .social-links{
            img{
                height: 22px;
                margin: 0 10px;
            }
        }
    }
}