@import '../../ui/media-query/media-query.scss';

.feedback-container {
  width: 49%;
  box-sizing: border-box;
  padding: 50px 50px 20px;
  background: #FFF;
  box-shadow: 0 2px 4px 1px #A9A9A9;
  border-radius: 12px;

  .title {
    font-size: 20px;
    font-weight: bolder;
    line-height: 1.3;
  }

  .qualitative-feedback {
    .textarea-container{
      border: 1px solid #CECECE;
      border-radius: 12px;
      textarea {
        width: 100%;
        height: 100%;
        margin: 10px 0px;
        box-sizing: border-box;
        padding: 5px 0px 0px 20px;
        font-size: 17px;
        font-family: Open Sans,sans-serif;
        color: #360A3D;
        border: none;
        line-height: 1.2;
        overflow-y: auto;
        resize: none;
        
      }
      textarea:focus{
        outline: none;
      }
      textarea::placeholder{
        line-height: 1.3;
        opacity: 0.4;
      }
      &.tru-textarea--has-value {
        border: 1px solid #360A3D;
      }
    }
  }

  .can-contact-checkbox {
    font-size: 10px;
    color: #360A3D;
    margin: 15px 0;
    input{
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    .checkbox-text {
      font-size: 12px;
      color: #360A3D;
      letter-spacing: -0.5px;
      vertical-align: super;
    }
  }

  .submit-button-container {
     margin:20px 0 50px;
     display: flex;
     justify-content: flex-end;
     button {
        width: 35%;
        padding: 10px;
        border-radius: 10px;
        font-size: 20px;
        background: #48c3ff;
        color: #FFF;
        cursor: pointer;
        border-style: none;
      }
      button:disabled {
        background:#d8d8d8;
      }
      button:focus{
        outline: none;
      }
    }

    .privacy-policy-container{
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      margin: 0 -30px;
      a{
        color: #48c3ff;
      }
    }

    .contact-form{
      .to-be-contacted-text{
        font-size: 17px;
        padding-bottom: 12px;
      }
      .form-text-input{
        margin: 20px 0;
      }
      input{
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 18px;
        border: 1px solid #f6f6f6;
        padding: 15px;
        margin: 5px 0;
      }
    }

}

@include tablet-landscape {
  .feedback-container {
    padding: 50px 30px 20px;
    
    .privacy-policy-container{  
      margin: 0 -10px;
    }

    .submit-button-container {
      margin:20px 0 30px;    
    }
  }
}

@include tablet {
  .feedback-container {
    width: 100%;
    padding: 20px 30px 10px;
    box-sizing: border-box;

    .qualitative-feedback {
      .textarea-container{
        textarea{
          padding: 0 0 0 20px;
        }
      }
    }

    .privacy-policy-container{  
      font-size: 15px;
      margin: 0px;
    }
  }
}

@include mobile {
  .feedback-container {
    width: 100%;
    padding: 20px 20px 10px; 
    box-sizing: border-box;
    margin: 0px;
    .title {
      font-size: 15px;
    }

    .qualitative-feedback {
      .textarea-container{
        textarea{
          font-size: 12px;
          padding: 0 0 0 10px;
        }
      }
    }

    .can-contact-checkbox {
      font-size: 9px;
      margin: 10px 0;
      input{
        width: 18px;
        height: 18px;
      }
      .checkbox-text {
        font-size: 11px;
      }  
    }

    .submit-button-container {
      margin:10px 0 15px;    
      button {
        padding: 10px;
        font-size: 15px;
      }
    }

    .privacy-policy-container{  
      font-size: 11px;
      margin: 0px;
    }

    .contact-form{
      font-size: 15px;
      .to-be-contacted-text{
        font-size: 15px;
        padding-bottom: 13px;
      }
      .form-text-input{
        margin: 11px 0;
      }
      input{
        font-size: 15px;
        padding: 12px;
      }
    }
    
  }
}

