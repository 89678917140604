@import '../../ui/media-query/media-query.scss';

.feedback-details-container{
    width: 49%;
    box-sizing: border-box;
    padding: 40px 50px;
    background: #FFF;
    box-shadow: 0 2px 4px 1px #A9A9A9;
    border-radius: 12px;
    font-size: 17px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .question{
        font-family: 'Caveat Brush';
        font-size: 25px;
        margin-bottom: 10px;
    }
    .first-ans{
        margin: 20px 0;
    }
    .charity{
        display: flex;
        align-items: center;
        img{
            height: 120px;
        }
        div{
            margin: 0px 30px;
            font-weight: bolder;
            color: #48c3ff;
        }
    }
}

@include tablet-landscape {
    .feedback-details-container {
        padding: 40px 30px;
    }
}

@include tablet {
    .feedback-details-container {
      width: 100%;
      padding: 25px 20px;
      box-sizing: border-box;
      margin: 0px;
      font-size: 17px;
      margin-top: 20px;
    }
  }

@include mobile {
    .feedback-details-container{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin: 0px;
        font-size: 14px;
        margin-top: 15px;

        .question{
            font-size: 20px;
        }

        .charity{
            img{
                height: 100px;
            }
        }

    }
}