@import '../../ui/media-query/media-query.scss';

.quantitative-feedback {
  margin: 30px 0;

  .rating-buttons, .rating-values {
    display: flex;
    justify-content: space-between;
  }

  .rating-buttons {
    .rating-button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      font-size: 18px;
      color: #360A3D;
      cursor: pointer;
      padding: 1px;
      box-shadow: 0 1px 1px 1px #a1a7b1, 0 0 0 2px #e8ebf0, 0 0 0 3px #d7d9dc;
      background:#F4F6F8;
      border-style: none;
      &.selected {
        background: #48c3ff;
        color: white;
      }
    }
    .rating-button:hover{
      background: #48c3ff;
      opacity: 0.6;
    }
    .rating-button:focus{
      outline: none;
    }
    .rating-button:active{
      border: none;
    }
  }

  .rating-values {
    font-size: 16px;
    margin-top: 12px;
    letter-spacing: -0.39px;
  }

  .multiple-options {
    width: 100%;
    justify-content: unset;
  }

  .binary-options{
    display: flex;
    align-items: center;
    margin-right: 60px;
  }

  .binary-label{
    font-size: 16px;
    margin-left: 15px;
  }
}

@include tablet-landscape {
  .quantitative-feedback {
    .rating-buttons {
      .rating-button {
        height: 30px;
        width: 30px;
      }
    }
  }

}

@include mobile {
  .quantitative-feedback {
    margin: 20px 0;
    .rating-buttons {
      .rating-button {
        height: 25px;
        width: 25px;
        font-size: 12px;
        box-shadow: 0 1px 1px 1px #a1a7b1;
      }
    }
    
    .rating-values {
      font-size: 11px;
    }
  }
}
