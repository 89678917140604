$phone-width: 480px;
$tablet-width: 768px;
$tablet-landscape-width: 1024px;

@mixin tablet-landscape {
  @media (max-width: #{$tablet-landscape-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

